import "../FormCreate.css";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useState } from "react";
import logoimg from "../../../components/Images/f7.jpg";
import toast, { Toaster } from "react-hot-toast";
import TermsImg from "../../../components/Images/english-school.png";

const AzeeziaEnglishSchoolForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    AdmissionSought: "",
    AcademicYear: "",
    Name: "",
    Gender: "",
    BloodGroup: "",
    FathersName: "",
    MothersName: "",
    FatherQualification: "",
    MotherQualification: "",
    FatherOccupation: "",
    MotherOccupation: "",
    FatherNumber: "",
    MotherNumber: "",
    Address:"",
    GuardianName: "",
    GuardianOccupation: "",
    GuardianAddress: "",
    GuardianNumber: "",
    Boardofsyllabus: "",
    NameofSchool: "",
    StandardlastStudied: "",
    StudyStatus: "",
    DetailsofVaccination: "",
    Height: "",
    Weight: "",
    RegularMedicine: "",
    transportation: "",
    boardingPoint: "",
    termsAccepted: false,
  });

  const fieldDisplayNames = {
    AdmissionSought: "",
    AcademicYear: "",
    Name: "Name of the Candidate",
    Gender: "",
    BloodGroup: "",
    FathersName: "Father's Name",
    FatherQualification: "Father's Qualification",
    FatherOccupation: "Father's Occupation",
    FatherNumber: "Father's Contact Number",
    MothersName: "Mother's Name",
    MotherQualification: "Mother's Qualification",
    MotherOccupation: "Mother's Occupation",
    MotherNumber: "Mother's Contact Number",
    Address: "Address with Pincode",
    GuardianName: "Guardian's Name",
    GuardianOccupation: "Guardian's Occupation",
    GuardianAddress: "Guardian's Address",
    GuardianNumber: "Guardian's Contact Number",
    Boardofsyllabus: "Board of Syllabus",
    NameofSchool: "",
    StandardlastStudied: "Standard last Studied",
    StudyStatus: "% or Grade of Mark",
    DetailsofVaccination: "Details of Vaccination",
    Height: "",
    Weight: "",
    RegularMedicine: " if using any Regular Medicine",
    transportation: " Whether School Transportation is required",
    boardingPoint: "State the Boarding Point",
    termsAccepted: false,
  };

  const [profileImage, setProfileImage] = useState(null);
  const [profileImagepreview, setProfileImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // Check if file size is greater than 70 KB
        setErrorMessage("Please upload an image smaller than 250 KB.");
        setProfileImage(null);
        setProfileImagePreview(null);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(file);
          setProfileImagePreview(reader.result);
          setErrorMessage(""); // Clear any previous error messages
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const [showDetails, setShowDetails] = useState({
    StudentDetails: true,
    FamilyDetails: true,
    GuardianDetails: true,
    PreviousSchoolDetails: true,
    HealthInformationDetails: true,
  });

  const handleFieldSelection = (fieldName) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let numericValue;
    let trimmedValue;
    if (
      name === "FatherNumber" ||
      name === "MotherNumber" ||
      name === "GuardianNumber"
    ) {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      const trimmedValue = numericValue.slice(0, 10); // Trim to 10 characters
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "Weight") {
      // Remove "kg" if it exists and only keep numeric part
      const numericValue = value.replace(/[^\d]/g, "");
      setFormData({ ...formData, [name]: numericValue + " kg" });
    } else if (name === "Height") {
      // Remove "kg" if it exists and only keep numeric part
      const numericValue = value.replace(/[^\d]/g, "");
      setFormData({ ...formData, [name]: numericValue + " cm" });
    }  else if (name === "Age") {
      // Remove any non-numeric characters and limit to 2 digits
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 2);
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "StudyStatus") {
      // Only allow numeric characters and limit to 2 digits
      numericValue = value.replace(/\D/g, ""); // Remove any non-numeric characters
      trimmedValue = numericValue.slice(0, 2); // Limit to 2 digits
      setFormData({ ...formData, [name]: trimmedValue });
    }else if (name === "termsAccepted") {
      // Check if terms are being accepted
      setFormData({ ...formData, termsAccepted: checked }); // Update termsAccepted state
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "cm",
      format: [21, 29.7],
    });
    const leftPadding = 2.5;
    const paddingTop = 1.3;
    const borderMargin = 1;
    const paddingBelowImage = 0.5;
    // const paddingAboveTable = 1;
    const imageWidth = 2.5;
    const imageHeight = 2.7;

    doc.setFontSize(38);
    doc.setTextColor("black");

    doc.setLineWidth(0.01);
    doc.rect(
      borderMargin,
      borderMargin,
      21 - 2 * borderMargin,
      29.7 - 2 * borderMargin
    );

    // Add logo image
    const desiredImgWidth = 18.7;
    const desiredImgHeight = 3;
    const aspectRatio = desiredImgWidth / desiredImgHeight;
    const imgWidth = desiredImgWidth;
    const imgHeight = imgWidth / aspectRatio;
    const imgX = (21 - imgWidth) / 2;
    const imgY = paddingTop;
    doc.addImage(logoimg, "PNG", imgX, imgY, imgWidth, imgHeight);

    // Add profile image (if needed)
    if (profileImage) {
      const imgData = URL.createObjectURL(profileImage);
      const imageX = 21 - leftPadding - imageWidth;
      const imageY = 1 + paddingBelowImage;
      doc.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);
    }

    // Add form data table
    const data = [];
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "termsAccepted") {
        // Get display name from fieldDisplayNames or fallback to key
        const displayName =
          fieldDisplayNames[key] || key.replace(/([A-Z])/g, " $1").trim();
        data.push([displayName, value]);
      }
    });

    const tablePaddingTop = paddingTop + paddingBelowImage + imageHeight + 0.9; // Adjust as needed
    doc.autoTable({
      startY: tablePaddingTop,
      body: data,
      theme: "plain",
      columnStyles: {
        0: { cellWidth: 6 },
        1: { cellWidth: 12, paddingLeft: 0.2 },
      },
      styles: {
        lineWidth: 0.05,
        cellHeight: 0.8,
      },
    });

    doc.addPage();

    // Calculate dimensions and position for the new image on Page 2
    const imgWidth2 = 21 - 2 * borderMargin; // Use full page width minus margins
    const imgHeight2 = 29.7 - 2 * borderMargin; // Use full page height minus margins
    const imgX2 = borderMargin;
    const imgY2 = borderMargin;

    // Add image to Page 2
    //const TermsImgData = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA..."; // Replace with your image data URL
    doc.addImage(TermsImg, "PNG", imgX2, imgY2, imgWidth2, imgHeight2);

    // Draw a border around the page for better visibility
    doc.setLineWidth(0.01);
    doc.rect(
      borderMargin,
      borderMargin,
      21 - 2 * borderMargin,
      29.7 - 2 * borderMargin
    );

    return doc.output("blob");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const pdfBlob = generatePdf();
      const formDataToSend = new FormData();
      const pdfFileName = "AZEEZIA ENGLISH SCHOOL APPLICATION FORM.pdf";
      formDataToSend.append("pdfFile", pdfBlob, pdfFileName);
      formDataToSend.append("pdfFileName", pdfFileName);
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await axios.post(
        "https://jamia-backend-vercel.vercel.app/admin/createForm",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully and email sent.");
      } else {
        console.error("Error submitting form:", response.data);
        toast.error("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    } finally {
      setIsSubmitting(false); // Set the submitting state back to false
    }
  };

  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Toaster position="top-center" />
      <h1 className="form-heading"> AZEEZIA ENGLISH SCHOOL APPLICATION FORM</h1>
      <form onSubmit={handleSubmit} className="form-jammia">
        <div className="table-div" style={{ paddingTop: "20px" }}>
          <Typography className="form-name asterisk">Admission Sought for</Typography>
          <TextField
            type="text"
            name="AdmissionSought"
            value={formData.AdmissionSought}
            onChange={handleChange}
            placeholder="Admission Sought"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Academic Year</Typography>
          <TextField
            type="number"
            name="AcademicYear"
            value={formData.AcademicYear}
            onChange={handleChange}
            placeholder="Academic Year"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <Button onClick={() => handleFieldSelection("StudentDetails")}>
          Student details{" "}
          {showDetails.StudentDetails ? (
            <ArrowDropUpIcon style={{ fontSize: "35px" }} />
          ) : (
            <ArrowDropDownIcon style={{ fontSize: "35px" }} />
          )}
        </Button>

        <div
          className={`details-container ${
            showDetails.StudentDetails ? "show" : ""
          }`}
        >
          <div className="table-div">
            <Typography className="form-name asterisk">Name of the Candidate</Typography>
            <TextField
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              placeholder="Name"
              className="textfield-form-jammia"
              size="small"
              required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name asterisk">Gender</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="Gender"
                value={formData.Gender}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">Age & DOB </Typography>
            <TextField
              type="number"
              name="Age"
              value={formData.Age}
              onChange={handleChange}
              placeholder="Age"
              // className="textfield-form-jammia"
              size="small"
            />
            <TextField
              type="date"
              name="DateOfBirth"
              value={formData.DateOfBirth}
              onChange={handleChange}
              placeholder="Date of Birth"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">Community/Religion</Typography>
            <TextField
              type="text"
              name="Religion"
              value={formData.Religion}
              onChange={handleChange}
              placeholder="Community/Religion"
              // required
              size="small"
              className="textfield-form-jammia"
            />
          </div>
          <Divider />

          <div className="table-div">
            <Typography className="form-name">Blood Group</Typography>
            <FormControl
              variant="outlined"
              size="small"
              className="textfield-form-jammia"
            >
              <Select
                value={formData.BloodGroup}
                onChange={handleChange}
                name="BloodGroup"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Blood Group
                </MenuItem>
                <MenuItem value="A+">A+</MenuItem>
                <MenuItem value="A-">A-</MenuItem>
                <MenuItem value="B+">B+</MenuItem>
                <MenuItem value="B-">B-</MenuItem>
                <MenuItem value="AB+">AB+</MenuItem>
                <MenuItem value="AB-">AB-</MenuItem>
                <MenuItem value="O+">O+</MenuItem>
                <MenuItem value="O-">O-</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <Divider /> */}
        </div>
        <Divider />
        <Button onClick={() => handleFieldSelection("FamilyDetails")}>
          Family Details{" "}
          {showDetails.FamilyDetails ? (
            <ArrowDropUpIcon style={{ fontSize: "35px" }} />
          ) : (
            <ArrowDropDownIcon style={{ fontSize: "35px" }} />
          )}
        </Button>

        <div
          className={`details-container ${
            showDetails.FamilyDetails ? "show" : ""
          }`}
        >
          <div className="table-div">
            <Typography className="form-name">Father's Name</Typography>
            <TextField
              type="text"
              name="FathersName"
              value={formData.FathersName}
              onChange={handleChange}
              placeholder="Father's Name"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">
              Father's Qualification
            </Typography>
            <TextField
              type="text"
              name="FatherQualification"
              value={formData.FatherQualification}
              onChange={handleChange}
              placeholder="Father's Qualification"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">Father's Occupation</Typography>
            <TextField
              type="text"
              name="FatherOccupation"
              value={formData.FatherOccupation}
              onChange={handleChange}
              placeholder="Father's Occupation"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div" style={{ position: "relative" }}>
            <Typography className="form-name">
              {" "}
              Father's Contact Number
            </Typography>
            <TextField
              type="text"
              name="FatherNumber"
              value={formData.FatherNumber}
              onChange={handleChange}
              placeholder="Father's Contact Number"
              className="textfield-form-jammia"
              size="small"
              // required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">Mother's Name</Typography>
            <TextField
              type="text"
              name="MothersName"
              value={formData.MothersName}
              onChange={handleChange}
              placeholder="Mother's Name"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">
              Mother's Qualification
            </Typography>
            <TextField
              type="text"
              name="MotherQualification"
              value={formData.MotherQualification}
              onChange={handleChange}
              placeholder="Mother's Qualification"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div">
            <Typography className="form-name">Mother's Occupation</Typography>
            <TextField
              type="text"
              name="MotherOccupation"
              value={formData.MotherOccupation}
              onChange={handleChange}
              placeholder="Mother's Occupation"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
          <Divider />
          <div className="table-div" style={{ position: "relative" }}>
            <Typography className="form-name">
              {" "}
              Mother's Contact Number
            </Typography>
            <TextField
              type="text"
              name="MotherNumber"
              value={formData.MotherNumber}
              onChange={handleChange}
              placeholder="Mother's Contact Number"
              className="textfield-form-jammia"
              size="small"
              // required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </div>
          <div className="table-div">
            <Typography className="form-name"> Full Address with Pincode </Typography>
            <TextField
              id="outlined-multiline-flexible"
              //  label="Multiline"
              multiline
              maxRows={3}
              type="text"
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              placeholder=" Full Address with Pincode"
              size="small"
              className="textfield-form-jammia"
            />
          </div>
        </div>
        <Divider />
        <Button onClick={() => handleFieldSelection("GuardianDetails")}>
          Guardian Details{" "}
          {showDetails.GuardianDetails ? (
            <ArrowDropUpIcon style={{ fontSize: "35px" }} />
          ) : (
            <ArrowDropDownIcon style={{ fontSize: "35px" }} />
          )}
        </Button>

        <div
          className={`details-container ${
            showDetails.GuardianDetails ? "show" : ""
          }`}
        >
          <div className="table-div">
            <Typography className="form-name">
              Name of Local Guardian
            </Typography>
            <TextField
              type="text"
              name="GuardianName"
              value={formData.GuardianName}
              onChange={handleChange}
              placeholder="Guardian's Name"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Occupation</Typography>
            <TextField
              type="text"
              name="GuardianOccupation"
              value={formData.GuardianOccupation}
              onChange={handleChange}
              placeholder="Guardian's Occupation"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Guardian's Address</Typography>
            <TextField
              id="outlined-multiline-flexible"
              //  label="Multiline"
              multiline
              maxRows={3}
              type="text"
              name="GuardianAddress"
              value={formData.GuardianAddress}
              onChange={handleChange}
              placeholder=" Guardian Address"
              size="small"
              className="textfield-form-jammia"
            />
          </div>
          <div className="table-div" style={{ position: "relative" }}>
            <Typography className="form-name">
              {" "}
              Guardian's Contact Number
            </Typography>
            <TextField
              type="text"
              name="GuardianNumber"
              value={formData.GuardianNumber}
              onChange={handleChange}
              placeholder="Guardian's Contact Number"
              className="textfield-form-jammia"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Divider />
        <Button onClick={() => handleFieldSelection("PreviousSchoolDetails")}>
          Previous School record{" "}
          {showDetails.PreviousSchoolDetails ? (
            <ArrowDropUpIcon style={{ fontSize: "35px" }} />
          ) : (
            <ArrowDropDownIcon style={{ fontSize: "35px" }} />
          )}
        </Button>

        <div
          className={`details-container ${
            showDetails.PreviousSchoolDetails ? "show" : ""
          }`}
        >
          <div className="table-div">
            <Typography className="form-name">Name of the School</Typography>
            <TextField
              type="text"
              name="NameofSchool"
              value={formData.NameofSchool}
              onChange={handleChange}
              placeholder=" Name of School"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Board of Syllabus</Typography>
            <TextField
              type="text"
              name="Boardofsyllabus"
              value={formData.Boardofsyllabus}
              onChange={handleChange}
              placeholder="Board of syllabus"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Standard Last Studied</Typography>
            <TextField
              type="text"
              name="StandardlastStudied"
              value={formData.StandardlastStudied}
              onChange={handleChange}
              placeholder="Standard last studied"
              size="small"
              className="textfield-form-jammia"
            />
          </div>

          <div className="table-div">
            <Typography className="form-name">
              Study Status <br />
              (% or Grade of Mark)
            </Typography>
            <TextField
              type="number"
              name="StudyStatus"
              value={formData.StudyStatus}
              onChange={handleChange}
              placeholder="% or Grade of Mark"
              size="small"
              className="textfield-form-jammia"
            />
          </div>
        </div>
        <Divider />
        <Button
          onClick={() => handleFieldSelection("HealthInformationDetails")}
        >
          Health information of Student{" "}
          {showDetails.HealthInformationDetails ? (
            <ArrowDropUpIcon style={{ fontSize: "35px" }} />
          ) : (
            <ArrowDropDownIcon style={{ fontSize: "35px" }} />
          )}
        </Button>

        <div
          className={`details-container ${
            showDetails.HealthInformationDetails ? "show" : ""
          }`}
        >
          <div className="table-div">
            <Typography className="form-name">
              Details of Vaccination
            </Typography>
            <TextField
              type="text"
              name="DetailsofVaccination"
              value={formData.DetailsofVaccination}
              onChange={handleChange}
              placeholder="Details of Vaccination"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Height</Typography>
            <TextField
              type="text"
              name="Height"
              value={formData.Height}
              onChange={handleChange}
              placeholder="Height"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div className="table-div">
            <Typography className="form-name">Weight</Typography>
            <TextField
              type="text"
              name="Weight"
              value={formData.Weight}
              onChange={handleChange}
              placeholder="Weight"
              className="textfield-form-jammia"
              size="small"
            />
          </div>

          <div className="table-div">
            <Typography className="form-name">
              if using any Regular Medicine
            </Typography>
            <TextField
              type="text"
              name="RegularMedicine"
              value={formData.RegularMedicine}
              onChange={handleChange}
              placeholder="if any regular medicine"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Whether School Transportation is required
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="transportation"
              name="transportation"
              value={formData.transportation}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        {formData.transportation === "yes" && (
          <div className="table-div">
            <Typography className="form-name">
              State the Boarding Point
            </Typography>
            <TextField
              type="text"
              name="boardingPoint"
              value={formData.boardingPoint}
              onChange={handleChange}
              placeholder="State the boarding point"
              className="textfield-form-jammia"
              size="small"
            />
          </div>
        )}
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Upload Image</Typography>
          <TextField
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        {profileImagepreview && (
          <div>
            <img
              src={profileImagepreview}
              alt="Profile Preview"
              width="100"
              height="100"
            />
          </div>
        )}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Divider />
        <div className="table-div">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleChange}
                name="termsAccepted"
                color="primary"
              />
            }
            label={
              <span>
                I accept the terms and conditions{" "}
                {/* <Link to="/MFZTermsConditions">view terms & conditions</Link> */}
              </span>
            }
          />
        </div>
        <Button
          variant="contained"
          disabled={!formData.termsAccepted || isSubmitting}
          type="submit"
        >
          {isSubmitting ? "Form is submitting..." : "Submit Form"}
        </Button>
      </form>
    </div>
  );
};

export default AzeeziaEnglishSchoolForm;
