import React, { createContext, useState } from 'react';

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [croppedImage, setCroppedImage] = useState(null);
  const [image, setImage] = useState(null);


  return (
    <ImageContext.Provider value={{ croppedImage,setCroppedImage,image, setImage}}>
      {children}
    </ImageContext.Provider>
  );
};
