import "../FormCreate.css";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, {useState } from "react";
import logoimg from "../../../components/Images/f4.jpg";
import TermsImg from "../../../components/Images/junior-arabic.png";
import toast, { Toaster } from "react-hot-toast";


const AzeeziaJuniorArabicCollageForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    Name: "",
    AddressofApplicant: "",
    Age: "",
    DateOfBirth: "",
    Panchayath: "",
    District: "",
    Landmark: "",
    school: "",
    Selectedschool: "",
    madrasa: "",
    Selectedmadrasa: "",
    IdentificationMarks: "",
    FathersName: "",
    FatherAddress: "",
    GuardianName: "",
    GuardianAddress: "",
    GuardianRelation: "",
    termsAccepted: false,
  });


  
  const fieldDisplayNames = {
    Name: "Name of the Candidate",
    AddressofApplicant: "Address of the Applicant",
    Age: "",
    DateOfBirth: "",
    Panchayath: "",
    District: "",
    Landmark: "",
    school: "",
    Selectedschool: "Selected School",
    madrasa: "",
    Selectedmadrasa: "",
    IdentificationMarks: "",
    FathersName: "Father's Name",
    FatherAddress: "Father's Address",
    GuardianName: "Guardian's Name",
    GuardianAddress: "Guardian's Address",
    GuardianRelation: "Relation of Guardian with Candidate",
    ContactNumber: "",

  };

  const [profileImage, setProfileImage] = useState(null);
  const [profileImagepreview, setProfileImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // Check if file size is greater than 70 KB
        setErrorMessage("Please upload an image smaller than 250 KB.");
        setProfileImage(null);
        setProfileImagePreview(null);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(file);
          setProfileImagePreview(reader.result);
          setErrorMessage(""); // Clear any previous error messages
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value,checked } = e.target;
    let numericValue;
    let trimmedValue;
    // If the field is ContactNumber, remove any non-numeric characters
    // and limit it to 10 digits
    if (name === "ContactNumber") {
      // Remove non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      // Limit to 10 digits
      const trimmedValue = numericValue.slice(0, 10);

      // Update the state with the cleaned up value
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "Age") {
      // Remove any non-numeric characters and limit to 2 digits
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 2);
      setFormData({ ...formData, [name]: trimmedValue });
    }else if (name === "termsAccepted") {
      // Check if terms are being accepted
      setFormData({ ...formData, termsAccepted: checked }); // Update termsAccepted state
    } else {
      // For other fields, update the state directly
      setFormData({ ...formData, [name]: value });
    }
  };



const generatePdf = () => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "cm",
    format: [21, 29.7],
  });
  const leftPadding = 2.5;
  const paddingTop = 1.3;
  const borderMargin = 1;
  const paddingBelowImage = 0.5;
  const imageWidth = 2.5;
  const imageHeight = 2.7;

  doc.setFontSize(38);
  doc.setTextColor("black");

  const drawBorder = () => {
    doc.setLineWidth(0.01);
    doc.rect(
      borderMargin,
      borderMargin,
      21 - 2 * borderMargin,
      29.7 - 2 * borderMargin
    );
  };

  // Draw border on the first page
  drawBorder();

  // Add logo image
  const desiredImgWidth = 18.7;
  const desiredImgHeight = 3;
  const aspectRatio = desiredImgWidth / desiredImgHeight;
  const imgWidth = desiredImgWidth;
  const imgHeight = imgWidth / aspectRatio;
  const imgX = (21 - imgWidth) / 2;
  const imgY = paddingTop;
  doc.addImage(logoimg, "PNG", imgX, imgY, imgWidth, imgHeight);

  // Add profile image (if needed)
  if (profileImage) {
    const imgData = URL.createObjectURL(profileImage);
    const imageX = 21 - leftPadding - imageWidth;
    const imageY = 1 + paddingBelowImage;
    doc.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);
  }

  // Add form data table
  const data = [];
  Object.entries(formData).forEach(([key, value]) => {
    if (key !== "termsAccepted") {
      // Get display name from fieldDisplayNames or fallback to key
      const displayName = fieldDisplayNames[key] || key.replace(/([A-Z])/g, " $1").trim();
      data.push([displayName, value]);
    }
  });

  const tablePaddingTop = paddingTop + paddingBelowImage + imageHeight + 0.9; // Adjust as needed
  doc.autoTable({
    startY: tablePaddingTop,
    body: data,
    theme: "plain",
    columnStyles: {
      0: { cellWidth: 6 },
      1: { cellWidth: 12, paddingLeft: 0.2 },
    },
    styles: {
      lineWidth: 0.05,
      cellHeight: 0.8,
    },
    didDrawPage: function (data) {
      drawBorder(); // Draw border on each new page
    },
  });

  doc.addPage();

  // Calculate dimensions and position for the new image on Page 2
  const imgWidth2 = 21 - 2 * borderMargin; // Use full page width minus margins
  const imgHeight2 = 29.7 - 2 * borderMargin; // Use full page height minus margins
  const imgX2 = borderMargin;
  const imgY2 = borderMargin;

  // Add image to Page 2
  doc.addImage(TermsImg, "PNG", imgX2, imgY2, imgWidth2, imgHeight2);

  // Draw a border around the second page for better visibility
  drawBorder();

  return doc.output("blob");
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    try {
      const pdfBlob = generatePdf();
      const formDataToSend = new FormData();
      const pdfFileName = "AZEEZIA JUNIOR ARABIC COLLEGE APPLICATION FORM.pdf";
      formDataToSend.append("pdfFile", pdfBlob, pdfFileName);
      formDataToSend.append("pdfFileName", pdfFileName);
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });
      const response = await axios.post(
        "https://jamia-backend-vercel.vercel.app/admin/createForm",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully and email sent.");
      } else {
        console.error("Error submitting form:", response.data);
        toast.error("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    }
    finally {
      setIsSubmitting(false); // Set the submitting state back to false
    }
  };

  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Toaster position="top-center" />
      <h1 className="form-heading">
        {" "}
        AZEEZIA JUNIOR ARABIC COLLEGE APPLICATION FORM
      </h1>
      <form onSubmit={handleSubmit} className="form-jammia">
        <div className="table-div" style={{ paddingTop: "20px" }}>
          <Typography className="form-name asterisk">Name of the Candidate</Typography>
          <TextField
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">
            Address of the Applicant
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            //  label="Multiline"
            multiline
            maxRows={3}
            type="text"
            name="AddressofApplicant"
            value={formData.AddressofApplicant}
            onChange={handleChange}
            placeholder=" Address of the applicant"
            required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Age & DOB </Typography>
          <TextField
            type="number"
            name="Age"
            value={formData.Age}
            onChange={handleChange}
            placeholder="Age"
            // className="textfield-form-jammia"
            required
            size="small"
          />
          <TextField
            type="date"
            name="DateOfBirth"
            value={formData.DateOfBirth}
            onChange={handleChange}
            placeholder="Date of Birth"
            size="small"
            required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Panchayath/Municipality</Typography>
          <TextField
            type="text"
            name="Panchayath"
            value={formData.Panchayath}
            onChange={handleChange}
            placeholder="Panchayath/Municipality"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">District</Typography>
          <TextField
            type="text"
            name="District"
            value={formData.District}
            onChange={handleChange}
            placeholder="District"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Landmark</Typography>
          <TextField
            type="text"
            name="Landmark"
            value={formData.Landmark}
            onChange={handleChange}
            placeholder="Landmark"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Previous School & Class</Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <TextField
              type="text"
              name="school"
              value={formData.school}
              onChange={handleChange}
              placeholder="Previous School"
              className="textfield-form-jammia"
              size="small"
              // required
            />
            <TextField
              type="text"
              name="madrasa"
              value={formData.madrasa}
              onChange={handleChange}
              placeholder="Madrasa"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Selected Course</Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <TextField
              type="text"
              name="Selectedschool"
              value={formData.Selectedschool}
              onChange={handleChange}
              placeholder=" School"
              className="textfield-form-jammia"
              size="small"
              // required
            />
            <TextField
              type="text"
              name="Selectedmadrasa"
              value={formData.Selectedmadrasa}
              onChange={handleChange}
              placeholder="Madrasa"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Identification Marks</Typography>
          <TextField
           id="outlined-multiline-flexible"
           multiline
           maxRows={3}
            type="text"
            name="IdentificationMarks"
            value={formData.IdentificationMarks}
            onChange={handleChange}
            placeholder="Identification Marks"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Father's Name</Typography>
          <TextField
            type="text"
            name="FathersName"
            value={formData.FathersName}
            onChange={handleChange}
            placeholder="Father Name"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Father's Address</Typography>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={3}
            type="text"
            name="FatherAddress"
            value={formData.FatherAddress}
            onChange={handleChange}
            placeholder=" Father's Address"
            // required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Guardian's Name</Typography>
          <TextField
            type="text"
            name="GuardianName"
            value={formData.GuardianName}
            onChange={handleChange}
            placeholder="Guardian's Name"
            className="textfield-form-jammia"
            // required
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Relation of Guardian with Candidate
          </Typography>
          <TextField
            type="text"
            name="GuardianRelation"
            value={formData.GuardianRelation}
            onChange={handleChange}
            placeholder="Relation of Guardian with Candidate"
            className="textfield-form-jammia"
            // required
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Guardian's Address</Typography>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={3}
            type="text"
            name="GuardianAddress"
            value={formData.GuardianAddress}
            onChange={handleChange}
            placeholder=" Guardian Address "
            // required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />

        <div className="table-div" style={{ position: "relative" }}>
          <Typography className="form-name asterisk">Contact Number</Typography>
          <TextField
            type="text"
            name="ContactNumber"
            value={formData.ContactNumber}
            onChange={handleChange}
            placeholder="Contact Number"
            className="textfield-form-jammia"
            size="small"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name asterisk">Upload Image</Typography>
          <TextField
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        {profileImagepreview && (
          <div>
            <img
              src={profileImagepreview}
              alt="Profile Preview"
              width="100"
              height="100"
            />
          </div>
        )}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Divider />
        <div className="table-div">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleChange}
                name="termsAccepted"
                color="primary"
              />
            }
            label={
              <span>
                I accept the terms and conditions
                {/* <Link to="/MFZTermsConditions">view terms & conditions</Link> */}
              </span>
            }
          />
        </div>
        <Button
          variant="contained"
          disabled={!formData.termsAccepted || isSubmitting}
          type="submit"
        >
           {isSubmitting ? "Form is submitting..." : "Submit Form"}
        </Button>
      </form>
    </div>
  );
};

export default AzeeziaJuniorArabicCollageForm;
