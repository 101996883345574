import React from "react";

const MFZTermsConditions = () => {
  return (
    <div style={{ marginLeft: "20px", textAlign: "left" }}>
      <h2>Terms & Conditions</h2>
      <ol
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          paddingLeft: "20px",
        }}
      >
        <li>
          Apply the learned knowledge in your own life and spread it in the
          entire community.
        </li>
        <li>
          Conduct Deeni Daa-wat by standing steady on the beliefs of Ahlusunnah.
        </li>
        <li>Work for the upliftment of Jamia Azeezia.</li>
        <li>
          Work for Sunnath Jama-ath and for the wellbeing of the society and for
          the nation.
        </li>
        <li>
          Submit the copies of your ID card / Aadhaar card & the testimony from
          your last educator along with two photographs.
        </li>
        <li>
          Those who do not have the prescribed attendance will not be eligible
          to write the final examination.
        </li>
        <li>
          If any graduate of this institution acts contrary to the above
          statements, his degree will be impaired and the institution,
          <br />
          the committee or the Ustads will not be held responsible for it.
        </li>
        <li>
          Actions including dismissal will be taken, if there is any violation
          of discipline or harmful actions from the students.
        </li>
      </ol>
    </div>
  );
};

export default MFZTermsConditions;
