import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import ArabicArtsCollegeForm from "./components/Form/Arabic&ArtsCollegeForm/ArabicArtsCollegeForm";
import AzeeziaEnglishSchoolForm from "./components/Form/AzeeziaEnglishSchoolForm/AzeeziaEnglishSchoolForm";
import AzeeziaJuniorArabicCollageForm from "./components/Form/AzeeziaJuniorArabicCollageForm/AzeeziaJuniorArabicCollageForm";
import ZeeQuePreschoolForm from "./components/Form/ZeeQuePreschoolForm/ZeeQuePreschoolForm";
import FormCreate from "./components/Form/FormCreate";
import HifzulQuranForm from "./components/Form/HifzulQuranForm/HifzulQuranForm";
import MFZTermsConditions from "./components/Form/MFZTermsConditions";
import BoardingMadrasaForm from "./components/Form/BoardingMadrasaForm/BoardingMadrasaForm";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ZeeQuePreschoolForm />} />
        <Route
          path="/arabic-arts-college-form"
          element={<ArabicArtsCollegeForm />}
        />
        <Route
          path="/azeezia-english-school-form"
          element={<AzeeziaEnglishSchoolForm />}
        />
        <Route
          path="/azeezia-junior-arabic-collage-form"
          element={<AzeeziaJuniorArabicCollageForm />}
        />
        <Route
          path="/BoardingMadrasaForm"
          element={<BoardingMadrasaForm />}
        />
        <Route path="/MFZ-Form" element={<FormCreate />} />
        <Route path="/hifzul-quran-form" element={<HifzulQuranForm />} />
        <Route path="/MFZTermsConditions" element={<MFZTermsConditions />} />
      </Routes>
    </div>
  );
}

export default App;
