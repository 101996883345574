import "../FormCreate.css";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
//import TermsImg from "../../Images/terms1.png";
import logoimg from "../../../components/Images/f2.jpg";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";

const HifzulQuranForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    Name: "",
    AddressofApplicant: "",
    FathersName: "",
    GuardianName: "",
    GuardianAddress: "",
    GuardianRelation: "",
    FatherOccupation: "",
    SchoolStandard: "",
    AnnualIncome: "",
    Age: "",
    DateOfBirth: "",
    ContactNumber: "",
    termsAccepted: false,
  });


  const fieldDisplayNames = {
    Name: "Name of the Candidate",
    AddressofApplicant: "Address of the Applicant",
    FathersName: "Father's Name",
    FatherAddress: "Father's Address",
    GuardianName: "Guardian's Name",
    GuardianAddress: "Guardian's Address",
    GuardianRelation: "Relation of Guardian with Candidate",
    FatherOccupation: "Father's Occupation",
    SchoolStandard: "",
    AnnualIncome: "",
    Age: "",
    DateOfBirth: "",
    ContactNumber: "",
    termsAccepted: false,};

  const [profileImage, setProfileImage] = useState(null);
  const [profileImagepreview, setProfileImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // Check if file size is greater than 70 KB
        setErrorMessage("Please upload an image smaller than 250 KB.");
        setProfileImage(null);
        setProfileImagePreview(null);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(file);
          setProfileImagePreview(reader.result);
          setErrorMessage("");
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let numericValue;
    let trimmedValue;
    if (name === "ContactNumber") {
      const numericValue = value.replace(/\D/g, "");

      // Limit to 10 digits
      const trimmedValue = numericValue.slice(0, 10);

      // Update the state with the cleaned up value
      setFormData({ ...formData, [name]: trimmedValue });
    }  else if (name === "Age") {
      // Remove any non-numeric characters and limit to 2 digits
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 2);
      setFormData({ ...formData, [name]: trimmedValue });
    }else if (name === "termsAccepted") {
      // Check if terms are being accepted
      setFormData({ ...formData, termsAccepted: checked }); // Update termsAccepted state
    } else {
      // For other fields, update the state directly
      setFormData({ ...formData, [name]: value });
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "cm",
      format: [21, 29.7],
    });
    const leftPadding = 2.5;
    const paddingTop = 1.3;
    const borderMargin = 1;
    const paddingBelowImage = 0.5;
    const imageWidth = 2.5;
    const imageHeight = 2.7;
  
    doc.setFontSize(38);
    doc.setTextColor("black");
  
    // Draw outer border
    doc.setLineWidth(0.01);
    doc.rect(
      borderMargin,
      borderMargin,
      21 - 2 * borderMargin,
      29.7 - 2 * borderMargin
    );
  
    // Add logo image
    const desiredImgWidth = 18.7;
    const desiredImgHeight = 3;
    const aspectRatio = desiredImgWidth / desiredImgHeight;
    const imgWidth = desiredImgWidth;
    const imgHeight = imgWidth / aspectRatio;
    const imgX = (21 - imgWidth) / 2;
    const imgY = paddingTop;
    doc.addImage(logoimg, "PNG", imgX, imgY, imgWidth, imgHeight);
  
    // Add profile image (if needed)
    if (profileImage) {
      const imgData = URL.createObjectURL(profileImage);
      const imageX = 21 - leftPadding - imageWidth;
      const imageY = 1 + paddingBelowImage;
      doc.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);
    }
  
    // Add form data table
    const data = [];
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "termsAccepted") {
        // Get display name from fieldDisplayNames or fallback to key
        const displayName = fieldDisplayNames[key] || key.replace(/([A-Z])/g, " $1").trim();
        data.push([displayName, value]);
      }
    });
  
    const tablePaddingTop = paddingTop + paddingBelowImage + imageHeight + 0.9; // Adjust as needed
    doc.autoTable({
      startY: tablePaddingTop,
      body: data,
      theme: "plain",
      columnStyles: {
        0: { cellWidth: 6 },
        1: { cellWidth: 12, paddingLeft: 0.2 },
      },
      styles: {
        lineWidth: 0.05,
        cellHeight: 0.8,
      },
    });
  
    // Add "place:", "date:", and "signature of the parent:" fields
    doc.setFontSize(12);
    const tableEndY = doc.previousAutoTable.finalY + 1; // Adjust spacing as needed
    const marginX = leftPadding;
    const marginY = tableEndY + 1; // Adjust as needed
  
    // Place field
    doc.text("Place:", marginX, marginY);
    doc.setLineWidth(0.01);
    
  
    // Date field
    const dateFieldY = marginY + 2; // Adjust vertical spacing as needed
    doc.text("Date:", marginX, dateFieldY);
    doc.setLineWidth(0.01);
   
  
    // Signature of the parent field
    const signatureFieldY = dateFieldY ; // Adjust vertical spacing as needed
    const signatureFieldX = 21 - leftPadding - 7; // Right align
    doc.text("Signature of the parent:", signatureFieldX, signatureFieldY);
    // doc.setLineWidth(0.01);

    const forOfficeUseOnlyY = signatureFieldY + 2; // Adjust as needed
    doc.setFontSize(14);
    doc.text("FOR OFFICE USE ONLY", 10.5, forOfficeUseOnlyY, null, null, 'center');
  
    // Add office use table
    const officeUseData = [
      ["Name:", ""],
      ["Admission Number:", ""],
      ["Interview Result:", ""],
      ["Date Of Admission:", ""],
    ];
    const officeUseTableY = forOfficeUseOnlyY + 1; // Adjust as needed
    doc.autoTable({
      startY: officeUseTableY,
      // head: [["Field", "Value"]],
      body: officeUseData,
      theme: "plain",
      columnStyles: {
        0: { cellWidth: 7 },
        1: { cellWidth: 10, paddingLeft: 0.2 },
      },
      styles: {
        lineWidth: 0.05,
        cellHeight: 0.8,
      },
    });
    return doc.output("blob");
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    try {
      const pdfBlob = generatePdf();
      const formDataToSend = new FormData();
      const pdfFileName = "  AZEEZIA HIFZUL QUAR'AN COLLEGE APPLICATION FORM.pdf";
      formDataToSend.append("pdfFile", pdfBlob, pdfFileName);
      formDataToSend.append("pdfFileName", pdfFileName);
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await axios.post(
        "https://jamia-backend-vercel.vercel.app/admin/createForm",

        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully and email sent.");
      } else {
        console.error("Error submitting form:", response.data);
        toast.error("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    }
    finally {
      setIsSubmitting(false); // Set the submitting state back to false
    }
  };

  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Toaster position="top-center" />
      <h1 className="form-heading">
        AZEEZIA HIFZUL QUAR'AN COLLEGE APPLICATION FORM
      </h1>
      <form onSubmit={handleSubmit} className="form-jammia">
        <div className="table-div" style={{ paddingTop: "20px" }}>
          <Typography className="form-name asterisk">Name of the Candidate</Typography>
          <TextField
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Father's Name</Typography>
          <TextField
            type="text"
            name="FathersName"
            value={formData.FathersName}
            onChange={handleChange}
            placeholder="Father Name"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">
            Address of the applicant
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            //  label="Multiline"
            multiline
            maxRows={3}
            type="text"
            name="AddressofApplicant"
            value={formData.AddressofApplicant}
            onChange={handleChange}
            placeholder="Address of the applicant"
             required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />
        <div className="table-div" style={{ position: "relative" }}>
          <Typography className="form-name asterisk">Contact Number</Typography>
          <TextField
            type="text"
            name="ContactNumber"
            value={formData.ContactNumber}
            onChange={handleChange}
            placeholder="Contact Number"
            className="textfield-form-jammia"
            size="small"
             required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Age & DOB </Typography>
          <TextField
            type="number"
            name="Age"
            value={formData.Age}
            onChange={handleChange}
            placeholder="Age"
            // className="textfield-form-jammia"
            required
            size="small"
          />
          <TextField
            type="date"
            name="DateOfBirth"
            value={formData.DateOfBirth}
            onChange={handleChange}
            placeholder="Date of Birth"
            size="small"
            required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">
            School Standard on Application
          </Typography>
          <TextField
            type="text"
            name="SchoolStandard"
            value={formData.SchoolStandard}
            onChange={handleChange}
            placeholder="School Standard on Application"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Guardian's Name</Typography>
          <TextField
            type="text"
            name="GuardianName"
            value={formData.GuardianName}
            onChange={handleChange}
            placeholder="Guardian's Name"
            className="textfield-form-jammia"
            //  required
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Guardian's Address</Typography>
          <TextField
            id="outlined-multiline-flexible"
            //  label="Multiline"
            multiline
            maxRows={3}
            type="text"
            name="GuardianAddress"
            value={formData.GuardianAddress}
            onChange={handleChange}
            placeholder=" Guardian Address "
            //  required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Relation of Guardian with Candidate{" "}
          </Typography>
          <TextField
            type="text"
            name="GuardianRelation"
            value={formData.GuardianRelation}
            onChange={handleChange}
            placeholder="Relation of Guardian with Candidate"
            className="textfield-form-jammia"
            //  required
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Occupation of the Parent
          </Typography>
          <TextField
            type="text"
            name="FatherOccupation"
            value={formData.FatherOccupation}
            onChange={handleChange}
            placeholder="Occupation of the Parent"
            className="textfield-form-jammia"
            size="small"
            //  required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Annual Income of the Parent
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            //  label="Multiline"
            type="text"
            name="AnnualIncome"
            value={formData.AnnualIncome}
            onChange={handleChange}
            placeholder="Annual Income of the Parent"
            // required
            size="small"
            className="textfield-form-jammia"
          />
        </div>

        <Divider />

        <div className="table-div">
          <Typography className="form-name asterisk">Upload Image</Typography>
          <TextField
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        {profileImagepreview && (
          <div>
            <img
              src={profileImagepreview}
              alt="Profile Preview"
              width="100"
              height="100"
            />
          </div>
        )}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Divider />
        <div className="table-div">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleChange}
                name="termsAccepted"
                color="primary"
              />
            }
            label={
              <span>
                I accept the terms and conditions{" "}
                {/* <Link to="/MFZTermsConditions">view terms & conditions</Link> */}
              </span>
            }
          />
        </div>
        <Divider />
        <Button
          variant="contained"
          disabled={!formData.termsAccepted || isSubmitting}
          type="submit"
        >
           {isSubmitting ? "Form is submitting..." : "Submit Form"}
        </Button>
      </form>
    </div>
  );
};

export default HifzulQuranForm;
