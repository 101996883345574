import "../FormCreate.css";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useState } from "react";
import logoimg from "../../../components/Images/f6.jpg";

import TermsImg from "../../Images/zeequee.png";
import toast, { Toaster } from "react-hot-toast";

const ZeeQuePreschoolForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    initial: "",
    Age: "",
    DateOfBirth: "",
    Height: "",
    Weight: "",
    BloodGroup: "",
    AdharNo: "",
    State: "",
    Nationality: "",
    IdentificationMarks1: "",
    IdentificationMarks2: "",
    FathersName: "",
    FatherAddress: "",
    FatherOccupation: "",
    MothersName: "",
    MotherOccupation: "",
    FatherQualification: "",
    MotherQualification: "",
    GuardianName: "",
    GuardianAddress: "",
    ContactNumber: "",
    AlternateNumber: "",
    email: "",
    Brother1: "",
    Class1: "",
    Div1: "",
    Brother2: "",
    Class2: "",
    Div2: "",
    BoardingPoint: "",
    termsAccepted: false,
  });

  const fieldDisplayNames = {
    Name: "Name of the Candidate",
    initial: "Name with Initial",
    Age: "",
    DateOfBirth: "",
    Height: "",
    Weight: "",
    BloodGroup: "",
    AdharNo: "",
    State: "",
    Nationality: "",
    IdentificationMarks1: "First Identification Mark",
    IdentificationMarks2: "Seciond Identification Mark",
    FathersName: "Father's Name",
    FatherAddress: "Father's Address",
    FatherOccupation: "Father's Occupation",
    MothersName: "Mother's Name",
    MotherOccupation: "Mother's Occupation",
    FatherQualification: "Father's Qualification",
    MotherQualification: "Mother's Qualification",
    GuardianName: "Guardian's Name",
    GuardianAddress: "Guardian's Address",
    ContactNumber: "",
    AlternateNumber: "",
    email: "Email",
    Brother1: "Name of Sibling",
    Class1: "class of Sibling",
    Div1: " Division of Sibling",
    Brother2: "Name of Sibling",
    Class2: "class of Sibling",
    Div2: "Division of Sibling",
    BoardingPoint: "Boarding Point with Landmark",
  };

  const [profileImage, setProfileImage] = useState(null);
  const [profileImagepreview, setProfileImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        setErrorMessage("Please upload an image smaller than 250 KB.");
        setProfileImage(null);
        setProfileImagePreview(null);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(file);
          setProfileImagePreview(reader.result);
          setErrorMessage(""); // Clear any previous error messages
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let numericValue;
    let trimmedValue;

    if (name === "ContactNumber" || name === "AlternateNumber") {
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 10);
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "AdharNo") {
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 12);
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "Weight") {
      numericValue = value.replace(/[^\d]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue ? numericValue + " kg" : "",
      });
    } else if (name === "Height") {
      numericValue = value.replace(/[^\d]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue ? numericValue + " cm" : "",
      });
    } else if (name === "Age") {
      // Remove any non-numeric characters and limit to 2 digits
      numericValue = value.replace(/\D/g, "");
      trimmedValue = numericValue.slice(0, 2);
      setFormData({ ...formData, [name]: trimmedValue });
    } else if (name === "termsAccepted") {
      setFormData({ ...formData, termsAccepted: checked }); // Update termsAccepted state
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "cm",
      format: [21, 29.7],
    });
    const leftPadding = 2.5;
    const paddingTop = 1.3;
    const borderMargin = 1;
    const paddingBelowImage = 0.5;
    const imageWidth = 2.5;
    const imageHeight = 2.7;

    doc.setFontSize(38);
    doc.setTextColor("black");

    const drawBorder = () => {
      doc.setLineWidth(0.01);
      doc.rect(
        borderMargin,
        borderMargin,
        21 - 2 * borderMargin,
        29.7 - 2 * borderMargin
      );
    };

    // Draw border on the first page
    drawBorder();

    // Add logo image
    const desiredImgWidth = 18.7;
    const desiredImgHeight = 3;
    const aspectRatio = desiredImgWidth / desiredImgHeight;
    const imgWidth = desiredImgWidth;
    const imgHeight = imgWidth / aspectRatio;
    const imgX = (21 - imgWidth) / 2;
    const imgY = paddingTop;
    doc.addImage(logoimg, "PNG", imgX, imgY, imgWidth, imgHeight);

    // Add profile image (if needed)
    if (profileImage) {
      const imgData = URL.createObjectURL(profileImage);
      const imageX = 21 - leftPadding - imageWidth;
      const imageY = 1 + paddingBelowImage;
      doc.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);
    }

    // Add form data table
    const data = [];
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "termsAccepted") {
        // Get display name from fieldDisplayNames or fallback to key
        const displayName =
          fieldDisplayNames[key] || key.replace(/([A-Z])/g, " $1").trim();
        data.push([displayName, value]);
      }
    });

    const tablePaddingTop = paddingTop + paddingBelowImage + imageHeight + 0.9; // Adjust as needed
    doc.autoTable({
      startY: tablePaddingTop,
      body: data,
      theme: "plain",
      columnStyles: {
        0: { cellWidth: 6 },
        1: { cellWidth: 12, paddingLeft: 0.2 },
      },
      styles: {
        lineWidth: 0.05,
        cellHeight: 0.8,
      },
      didDrawPage: function (data) {
        drawBorder(); // Draw border on each new page
      },
    });

    doc.addPage();

    // Calculate dimensions and position for the new image on Page 2
    const imgWidth2 = 21 - 2 * borderMargin; // Use full page width minus margins
    const imgHeight2 = 29.7 - 2 * borderMargin; // Use full page height minus margins
    const imgX2 = borderMargin;
    const imgY2 = borderMargin;

    // Add image to Page 2
    doc.addImage(TermsImg, "PNG", imgX2, imgY2, imgWidth2, imgHeight2);

    // Draw a border around the second page for better visibility
    drawBorder();

    return doc.output("blob");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const pdfBlob = generatePdf();
      const formDataToSend = new FormData();
      const pdfFileName = "ZeeQue Preschool Network Application Form.pdf";
      formDataToSend.append("pdfFile", pdfBlob, pdfFileName);
      formDataToSend.append("pdfFileName", pdfFileName);
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await axios.post(
        "https://jamia-backend-vercel.vercel.app/admin/createForm",

        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully and email sent.");
      } else {
        console.error("Error submitting form:", response.data);
        toast.error("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    } finally {
      setIsSubmitting(false); // Set the submitting state back to false
    }
  };

  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Toaster position="top-center" />
      <h1 className="form-heading">
        ZeeQue Preschool Network Application Form
      </h1>
      <form onSubmit={handleSubmit} className="form-jammia">
        <div className="table-div" style={{ paddingTop: "12px" }}>
          <Typography className="form-name asterisk">Name with Initial</Typography>
          <TextField
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div" style={{ paddingTop: "12px" }}>
          <Typography className="form-name">Expansion of Initial</Typography>
          <TextField
            type="text"
            name="initial"
            value={formData.initial}
            onChange={handleChange}
            placeholder="Expansion of initial"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name asterisk">Age & DOB </Typography>
          <TextField
            type="number"
            name="Age"
            value={formData.Age}
            onChange={handleChange}
            placeholder="Age"
            // className="textfield-form-jammia"
            required
            size="small"
          />
          <TextField
            type="date"
            name="DateOfBirth"
            value={formData.DateOfBirth}
            onChange={handleChange}
            placeholder="Date of Birth"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Gender</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="Gender"
              value={formData.Gender}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                style={{ paddingRight: "10px" }}
                value="Boy"
                control={<Radio />}
                label="Boy"
              />
              <FormControlLabel value="Girl" control={<Radio />} label="Girl" />
            </RadioGroup>
          </FormControl>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {" "}
            <Typography>Weight</Typography>
            <TextField
              type="text"
              name="Weight"
              value={formData.Weight}
              onChange={handleChange}
              placeholder="Weight"
              style={{ maxWidth: "80px" }}
              //   className="textfield-form-jammia"
              size="small"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {" "}
            <Typography>Height</Typography>
            <TextField
              type="text"
              name="Height"
              value={formData.Height}
              onChange={handleChange}
              placeholder="Height"
              style={{ maxWidth: "80px" }}
              //   className="textfield-form-jammia"
              size="small"
            />
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Blood Group</Typography>
          <FormControl
            variant="outlined"
            size="small"
            className="textfield-form-jammia"
          >
            <Select
              value={formData.BloodGroup}
              onChange={handleChange}
              name="BloodGroup"
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Blood Group
              </MenuItem>
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Aadhar No</Typography>
          <TextField
            type="text"
            name="AdharNo"
            value={formData.AdharNo}
            onChange={handleChange}
            placeholder="Aadhar Number"
            className="textfield-form-jammia"
            size="small"
            required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">State</Typography>
          <TextField
            type="text"
            name="State"
            value={formData.State}
            onChange={handleChange}
            placeholder="State"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Nationality</Typography>
          <TextField
            type="text"
            name="Nationality"
            value={formData.Nationality}
            onChange={handleChange}
            placeholder="Nationality"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Father's Name</Typography>
          <TextField
            type="text"
            name="FathersName"
            value={formData.FathersName}
            onChange={handleChange}
            placeholder="Father's Name"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Father's Occupation</Typography>
          <TextField
            type="text"
            name="FatherOccupation"
            value={formData.FatherOccupation}
            onChange={handleChange}
            placeholder="Father's Occupation"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Father's Qualification</Typography>
          <TextField
            type="text"
            name="FatherQualification"
            value={formData.FatherQualification}
            onChange={handleChange}
            placeholder="Father's Qualification"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Mother's Name</Typography>
          <TextField
            type="text"
            name="MothersName"
            value={formData.MothersName}
            onChange={handleChange}
            placeholder="Mother's Name"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Mother's Occupation</Typography>
          <TextField
            type="text"
            name="MotherOccupation"
            value={formData.MotherOccupation}
            onChange={handleChange}
            placeholder="Mother's Occupation"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Mother's Qualification</Typography>
          <TextField
            type="text"
            name="MotherQualification"
            value={formData.MotherQualification}
            onChange={handleChange}
            placeholder="Mother's Qualification"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">Guardian's Name</Typography>
          <TextField
            type="text"
            name="GuardianName"
            value={formData.GuardianName}
            onChange={handleChange}
            placeholder="Guardian's Name"
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Address of Parent/Guardian
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={3}
            type="text"
            name="FatherAddress"
            value={formData.FatherAddress}
            onChange={handleChange}
            placeholder="Adress of Parent/Guardian"
            // required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name asterisk">
            Address for Communication
          </Typography>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={3}
            type="text"
            name="GuardianAddress"
            value={formData.GuardianAddress}
            onChange={handleChange}
            placeholder="Address for communication"
            required
            size="small"
            className="textfield-form-jammia"
          />
        </div>
        <Divider />

        <div className="table-div" style={{ position: "relative" }}>
          <Typography className="form-name asterisk">Contact Number </Typography>
          <TextField
            type="text"
            name="ContactNumber"
            value={formData.ContactNumber}
            onChange={handleChange}
            placeholder="Contact Number"
            className="textfield-form-jammia"
            size="small"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
        </div>
        <Divider />
        <div className="table-div" style={{ position: "relative" }}>
          <Typography className="form-name">Alternate Number </Typography>
          <TextField
            type="text"
            name="AlternateNumber"
            value={formData.AlternateNumber}
            onChange={handleChange}
            placeholder="Alternate Number"
            className="textfield-form-jammia"
            size="small"
            // required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
        </div>
        <Divider />
        <div className="table-div" style={{ position: "relative" }}>
          <Typography className="form-name">E-mail </Typography>
          <TextField
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-mail"
            className="textfield-form-jammia"
            size="small"
            // required
          />
        </div>
        <Divider />

        <div className="table-div">
          <Typography className="form-name">Identification Marks</Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={2}
              type="text"
              name="IdentificationMarks1"
              value={formData.IdentificationMarks1}
              onChange={handleChange}
              placeholder="First Identification Mark"
              className="textfield-form-jammia"
              size="small"
              // required
            />
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={2}
              type="text"
              name="IdentificationMarks2"
              value={formData.IdentificationMarks2}
              onChange={handleChange}
              placeholder="Second Identification Mark"
              className="textfield-form-jammia"
              size="small"
              // required
            />
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name">
            Name of Sibling (Brother/Sister)
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="studying-brother">
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={2}
                type="text"
                name="Brother1"
                value={formData.Brother1}
                onChange={handleChange}
                placeholder="Brother / Sister"
                // className="textfield-form-jammia"
                style={{ maxWidth: "200px" }}
                size="small"
              />{" "}
              <Typography>Class</Typography>
              <TextField
                type="text"
                name="Class1"
                value={formData.Class1}
                onChange={handleChange}
                placeholder="Class"
                style={{ maxWidth: "80px" }}
                //   className="textfield-form-jammia"
                size="small"
              />
              <Typography>Div</Typography>
              <TextField
                type="text"
                name="Div1"
                value={formData.Div1}
                onChange={handleChange}
                placeholder="Divison"
                style={{ maxWidth: "80px" }}
                //   className="textfield-form-jammia"
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="table-div">
          <Typography className="form-name no-asterisk">
            Studying in this unit (with class)
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="studying-brother">
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={2}
                type="text"
                name="Brother2"
                value={formData.Brother2}
                onChange={handleChange}
                placeholder="Studying Class"
                // className="textfield-form-jammia"
                style={{ maxWidth: "200px" }}
                size="small"
              />{" "}
              <Typography>Class</Typography>
              <TextField
                type="text"
                name="Class2"
                value={formData.Class2}
                onChange={handleChange}
                placeholder="Class"
                style={{ maxWidth: "80px" }}
                //   className="textfield-form-jammia"
                size="small"
              />
              <Typography>Div</Typography>
              <TextField
                type="text"
                name="Div2"
                value={formData.Div2}
                onChange={handleChange}
                placeholder="Divison"
                style={{ maxWidth: "80px" }}
                //   className="textfield-form-jammia"
                size="small"
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name no-asterisk">
            Boarding Point with Landmark
          </Typography>
          <TextField
            type="text"
            name="BoardingPoint"
            value={formData.BoardingPoint}
            onChange={handleChange}
            placeholder="Boarding Point with Landmark"
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        <Divider />
        <div className="table-div">
          <Typography className="form-name asterisk">Upload image</Typography>
          <TextField
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
            className="textfield-form-jammia"
            size="small"
          />
        </div>
        {profileImagepreview && (
          <div>
            <img
              src={profileImagepreview}
              alt="Profile Preview"
              width="100"
              height="100"
            />
          </div>
        )}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Divider />
        <div className="table-div">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleChange}
                name="termsAccepted"
                color="primary"
              />
            }
            label={
              <span>
                I accept the terms and conditions{" "}
                {/* <Link to="/MFZTermsConditions">view terms & conditions</Link> */}
              </span>
            }
          />
        </div>
        <Divider />
        <Button
          variant="contained"
          disabled={!formData.termsAccepted || isSubmitting}
          type="submit"
        >
          {isSubmitting ? "Form is submitting..." : "Submit Form"}
        </Button>
      </form>
    </div>
  );
};

export default ZeeQuePreschoolForm;
